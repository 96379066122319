import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"politika-konfidencijnosti"} />
		<Helmet>
			<title>
				ARTPOTTERY
			</title>
			<meta name={"description"} content={"ArtPottery надає можливість поринути у світ керамічного мистецтва через наші захоплюючі та інтерактивні майстер-класи."} />
			<meta property={"og:title"} content={"ARTPOTTERY"} />
			<meta property={"og:description"} content={"ArtPottery надає можливість поринути у світ керамічного мистецтва через наші захоплюючі та інтерактивні майстер-класи."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/66ed22bbe5d277001897f3ed/images/8540798.png?v=2024-09-20T07:30:04.717Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/66ed22bbe5d277001897f3ed/images/8540798.png?v=2024-09-20T07:30:04.717Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/66ed22bbe5d277001897f3ed/images/8540798.png?v=2024-09-20T07:30:04.717Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/66ed22bbe5d277001897f3ed/images/8540798.png?v=2024-09-20T07:30:04.717Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/66ed22bbe5d277001897f3ed/images/8540798.png?v=2024-09-20T07:30:04.717Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/66ed22bbe5d277001897f3ed/images/8540798.png?v=2024-09-20T07:30:04.717Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/66ed22bbe5d277001897f3ed/images/8540798.png?v=2024-09-20T07:30:04.717Z"} />
		</Helmet>
		<Components.Header12>
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="link2" />
			<Override slot="link3" />
			{"    "}{"    "}{"    "}{"    "}
		</Components.Header12>
		<Section padding="100px 0" background="--color-light">
			{"    "}
			<Box
				max-width="800px"
				margin="0 auto"
				padding="0 20px"
				display="flex"
				flex-direction="column"
				align-items="center"
			>
				{"        "}
				<Text as="h1" font="--headline1" margin="0 0 40px 0" text-align="center">
					{"            "}Політика конфіденційності{"\n        "}
				</Text>
				{"        "}
				<Text font="--base" margin="0 0 24px 0" color="--dark">
					{"            "}Ця політика конфіденційності пояснює, як ArtPottery ("ми", "наш", "нас") збирає, використовує та захищає ваші персональні дані, коли ви відвідуєте наш сайт або користуєтеся нашими послугами.{"\n        "}
				</Text>
				{"        "}
				<Text as="h2" font="--headline3" margin="40px 0 24px 0">
					{"            "}1. Яку інформацію ми збираємо{"\n        "}
				</Text>
				{"        "}
				<Text font="--base" margin="0 0 24px 0" color="--dark">
					{"            "}Ми можемо збирати таку інформацію:{"\n        "}
				</Text>
				{"        "}
				<Text font="--base" margin="0 0 8px 0" color="--dark">
					{"            "}• Ім'я, контактна інформація (електронна адреса та номер телефону){"\n        "}
				</Text>
				{"        "}
				<Text font="--base" margin="0 0 8px 0" color="--dark">
					{"            "}• Дані для бронювання, коли ви реєструєтесь на наші майстер-класи{"\n        "}
				</Text>
				{"        "}
				<Text font="--base" margin="0 0 8px 0" color="--dark">
					{"            "}• Технічні дані про ваше відвідування сайту (IP-адреса, браузер, час доступу){"\n        "}
				</Text>
				{"        "}
				<Text as="h2" font="--headline3" margin="40px 0 24px 0">
					{"            "}2. Як ми використовуємо вашу інформацію{"\n        "}
				</Text>
				{"        "}
				<Text font="--base" margin="0 0 24px 0" color="--dark">
					{"            "}Ми використовуємо вашу інформацію для:{"\n        "}
				</Text>
				{"        "}
				<Text font="--base" margin="0 0 8px 0" color="--dark">
					{"            "}• Обробки ваших замовлень та запитів{"\n        "}
				</Text>
				{"        "}
				<Text font="--base" margin="0 0 8px 0" color="--dark">
					{"            "}• Поліпшення наших послуг та досвіду користувача{"\n        "}
				</Text>
				{"        "}
				<Text font="--base" margin="0 0 8px 0" color="--dark">
					{"            "}• Маркетингових повідомлень, якщо ви на це погоджуєтесь{"\n        "}
				</Text>
				{"        "}
				<Text as="h2" font="--headline3" margin="40px 0 24px 0">
					{"            "}3. Як ми захищаємо вашу інформацію{"\n        "}
				</Text>
				{"        "}
				<Text font="--base" margin="0 0 24px 0" color="--dark">
					{"            "}Ми приймаємо відповідні технічні та організаційні заходи для захисту ваших даних від несанкціонованого доступу, зміни, втрати або видалення.{"\n        "}
				</Text>
				{"        "}
				<Text as="h2" font="--headline3" margin="40px 0 24px 0">
					{"            "}4. Ваші права{"\n        "}
				</Text>
				{"        "}
				<Text font="--base" margin="0 0 24px 0" color="--dark">
					{"            "}Ви маєте право запитати доступ до своїх даних, їх виправлення або видалення. Також ви можете відкликати свою згоду на обробку даних у будь-який час.{"\n        "}
				</Text>
				{"        "}
				<Text as="h2" font="--headline3" margin="40px 0 24px 0">
					{"            "}5. Контактна інформація{"\n        "}
				</Text>
				{"        "}
				<Text font="--base" margin="0 0 24px 0" color="--dark">
					{"            "}Якщо у вас є запитання стосовно цієї політики конфіденційності, будь ласка, зв’яжіться з нами:{"\n            "}
					<br />
					{"            "}ArtPottery Studio{"\n            "}
					<br />
					{"            "}Pushkinska St, 19, Zhytomyr, Zhytomyr Oblast, 10014{"\n            "}
					<br />
					{"            "}Телефон: 0412473259{"\n            "}
					<br />
					{"            "}Електронна пошта: info@artpottery.ua{"\n        "}
				</Text>
				{"    "}
			</Box>
		</Section>
		<Components.Footer12>
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="link2" />
			<Override slot="link3" />
			{"    "}{"    "}{"    "}{"    "}
		</Components.Footer12>
		<Components.Policy />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"658eb1148e2e8e00217540ff"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});